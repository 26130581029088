import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../components/texts/parseData";
import parseAndFormat from "../../../../components/texts/parseAndFormat";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            overflowY: "scroll",
            padding: "0 24px 50px",
            maxHeight: "200px",
        },
        table: {
            border: `1px solid ${theme.palette.grey[300]}`,
            // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
            //   backgroundColor: theme.palette.grey[50],
            // },
            "& .MuiTableCell-root": {
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: theme.spacing(1),
                ...theme.typography.body2,
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontWeight: 500,
            },
        },
        bold: {
            fontWeight: "bold",
        },
        green: {
            backgroundColor: "#cce6d8",
        },
        darkGreen: {
            backgroundColor: "#8fcc60",
        },
        red: {
            backgroundColor: "#ffdad1",
        },
        yellow: {
            backgroundColor: "#ffd588",
        },
        white: {
            backgroundColor: "#ffffff",
        },
    })
);

const tableHeadings = [
    "Fulfillment status",
    "Requirement",
    "Required volume",
    "Joint fulfillment",
    "Allocated (L15)",
    "Surplus from off-road",
    "Remaining volume",
];

// const tableRows = new Array(10).fill(tableRow);

interface Props {
    requirement_quantities: any;
}

interface OverViewParamProps {
    general_mandate: number;
    general_req_vol: number;
    general_rel_vol: number;
    fame_advanced: number;
    hvo_hro_advanced: number;
    fame: number;
    hvo_hro: number;
    above_mandate_req_by_cust: number;
    above_mandate_after_fulfill: number;
    vol_not_applicable_to_mandate: number;
}

interface OverViewReqParamProps {
    general_mandate: number;
    general_req_vol: number;
    general_rel_vol: number;
    fame_advanced: number;
    hvo_hro_advanced: number;
    fame: number;
    hvo_hro: number;
    above_mandate_req_by_cust: number;
    above_mandate_after_fulfill: number;
    vol_not_applicable_to_mandate: number;
}

interface OverViewProps {
    req_vol: OverViewReqParamProps;
    joint_fulfill: OverViewParamProps;
    allocated_LE: OverViewParamProps;
    allocated_15: OverViewParamProps;
    offroad_surplus: OverViewParamProps;
    remaining_vol: OverViewParamProps;
}

interface OverviewTableProps {
    step: number;
    data: OverViewProps;
}

const OverviewTable: React.FC<OverviewTableProps> = ({
                                                         step,
                                                         data
                                                     }) => {
    const classes = useStyles();

    const getStatus = (remaining: any) => {
        if (remaining > 0) { return "Not Complete"; }
        if (remaining < 0) { return "Overallocated"; }
        return "Complete";
    };

    const getResult = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return ((parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0) || reqVol.toString() === "?")
            ? "Not relevant"
            : parseFloat(remainingVol.toString()) > 0
                ? "NOT fulfilled"
                : parseFloat(remainingVol.toString()) < 0
                    ? "Over-allocated"
                    : "Mandate fulfilled";
    };

    const setColor = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return  ((parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0) || reqVol.toString() === "?")
            ? classes.red
            : parseFloat(remainingVol.toString()) > 0
                ? classes.red
                : parseFloat(remainingVol.toString()) < 0
                    ? classes.yellow
                    : classes.darkGreen;
    };

    const getFuelTypeResult = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0
            ? classes.white
            : parseFloat(remainingVol.toString()) > 0
                ? classes.red
                : parseFloat(remainingVol.toString()) < 0
                    ? classes.yellow
                    : classes.green;
    };

    return (
        <div className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {tableHeadings.map((heading, hidx) => (
                            <TableCell key={hidx}>{heading}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {step > 0 && (
                        <TableRow
                            style={{
                                border: step === 1 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.general_req_vol,
                                data.allocated_15.general_req_vol,
                                data.remaining_vol.general_mandate
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.general_req_vol,
                                data.allocated_15.general_req_vol,
                                data.remaining_vol.general_mandate
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>General mandate ({data.req_vol.general_mandate}%)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.general_req_vol, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.general_mandate, 2)}</TableCell>
                        </TableRow>
                    )}
                    {step > 1 && (
                        <TableRow
                            style={{
                                border: step === 2 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_15.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_15.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>Above the mandate (required by customers)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.above_mandate_req_by_cust, 2)}</TableCell>
                        </TableRow>
                    )}
                    {step > 2 && (
                        <TableRow
                            style={{
                                border: step === 3 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_15.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_15.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>Above the mandate (after mandate fulfillment)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.above_mandate_after_fulfill, 2)}</TableCell>
                        </TableRow>
                    )}

                    {step > 3 && (
                        <TableRow
                            style={{
                                border: step === 4 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.vol_not_applicable_to_mandate,
                                data.allocated_15.vol_not_applicable_to_mandate,
                                data.remaining_vol.vol_not_applicable_to_mandate
                            )}
                        >
                            <TableCell>{getResult(
                                data.req_vol.vol_not_applicable_to_mandate,
                                data.allocated_15.vol_not_applicable_to_mandate,
                                data.remaining_vol.vol_not_applicable_to_mandate
                            )}</TableCell>
                            <TableCell>Bio volumes not applicable to the mandate</TableCell>
                            <TableCell>{parseAndFormat(data.req_vol.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.joint_fulfill.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.allocated_15.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.offroad_surplus.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.remaining_vol.vol_not_applicable_to_mandate, 2)}</TableCell>
                        </TableRow>
                    )}
                    {step < 4 && (
                        <>
                            <TableRow
                                className={setColor(
                                    data.req_vol.fame_advanced,
                                    data.allocated_15.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                )}
                            >
                                <TableCell>{getResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_15.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                )}</TableCell>
                                <TableCell>FAME</TableCell>
                                <TableCell>{parseAndFormat(data.req_vol.fame_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.joint_fulfill.fame_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.allocated_15.fame_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.offroad_surplus.fame_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.remaining_vol.fame_advanced, 2)}</TableCell>
                            </TableRow>
                            <TableRow
                                className={setColor(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_15.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                )}
                            >
                                <TableCell>{getResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_15.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                )}</TableCell>
                                <TableCell>HVO/HRO</TableCell>
                                <TableCell>{parseAndFormat(data.req_vol.hvo_hro_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.joint_fulfill.hvo_hro_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.allocated_15.hvo_hro_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.offroad_surplus.hvo_hro_advanced, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.remaining_vol.hvo_hro_advanced, 2)}</TableCell>
                            </TableRow>
                        </>
                    )}

                    {step == 4 && (
                        <>
                            <TableRow
                                className={setColor(
                                    data.req_vol.fame,
                                    data.allocated_15.fame,
                                    data.remaining_vol.fame
                                )}
                            >
                                <TableCell>{getResult(
                                    data.req_vol.fame,
                                    data.allocated_15.fame,
                                    data.remaining_vol.fame
                                )}</TableCell>
                                <TableCell>FAME</TableCell>
                                <TableCell>{parseAndFormat(data.req_vol.fame, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.joint_fulfill.fame, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.allocated_15.fame, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.offroad_surplus.fame, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.remaining_vol.fame, 2)}</TableCell>
                            </TableRow>
                            <TableRow
                                className={setColor(
                                    data.req_vol.hvo_hro,
                                    data.allocated_15.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                )}
                            >
                                <TableCell>{getResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_15.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                )}</TableCell>
                                <TableCell>HVO/HRO</TableCell>
                                <TableCell>{parseAndFormat(data.req_vol.hvo_hro, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.joint_fulfill.hvo_hro, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.allocated_15.hvo_hro, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.offroad_surplus.hvo_hro, 2)}</TableCell>
                                <TableCell>{parseAndFormat(data.remaining_vol.hvo_hro, 2)}</TableCell>
                            </TableRow>
                        </>
                    )}

                    {step < 1 && (
                        <TableRow
                            style={{
                                borderTop: step === 1 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.general_req_vol,
                                data.allocated_15.general_req_vol,
                                data.remaining_vol.general_mandate
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.general_req_vol,
                                data.allocated_15.general_req_vol,
                                data.remaining_vol.general_mandate
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>General mandate ({data.req_vol.general_mandate}%)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.general_req_vol, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.general_mandate, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.general_mandate, 2)}</TableCell>
                        </TableRow>
                    )}
                    {step < 2 && (
                        <TableRow
                            style={{
                                borderTop: step === 2 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_15.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_15.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>Above the mandate (required by customers)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.above_mandate_req_by_cust, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.above_mandate_req_by_cust, 2)}</TableCell>
                        </TableRow>
                    )}
                    {step < 3 && (
                        <TableRow
                            style={{
                                borderTop: step === 2 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_15.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{getResult(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_15.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>Above the mandate (after mandate fulfillment)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.req_vol.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.joint_fulfill.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.allocated_15.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.offroad_surplus.above_mandate_after_fulfill, 2)}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "grey",
                            }}>{parseAndFormat(data.remaining_vol.above_mandate_after_fulfill, 2)}</TableCell>
                        </TableRow>
                    )}

                    {step < 4 && (
                        <TableRow
                            style={{
                                borderTop: step === 3 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.vol_not_applicable_to_mandate,
                                data.allocated_15.vol_not_applicable_to_mandate,
                                data.remaining_vol.vol_not_applicable_to_mandate
                            )}
                        >
                            <TableCell>{getResult(
                                data.req_vol.vol_not_applicable_to_mandate,
                                data.allocated_15.vol_not_applicable_to_mandate,
                                data.remaining_vol.vol_not_applicable_to_mandate
                            )}</TableCell>
                            <TableCell>Bio volumes not applicable to the mandate</TableCell>
                            <TableCell>{parseAndFormat(data.req_vol.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.joint_fulfill.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.allocated_15.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.offroad_surplus.vol_not_applicable_to_mandate, 2)}</TableCell>
                            <TableCell>{parseAndFormat(data.remaining_vol.vol_not_applicable_to_mandate, 2)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default OverviewTable;
