import React, {Props, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ButtonRounded from "../../../components/ButtonRounded";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ModalContainer from "../../../components/ModalContainer";
import moment from "moment";
import {useHistory} from "react-router-dom";
import { getRoadUseData, getRoadUseCreateData, getRoadUseEditData } from "../../../api/sustainability/allocation/roadUse/data";
import excise_duty_rates from "../../../views/DashboardView/SettingsView/SettingProperties/excise_duty_rates";
import clsx from "clsx";
import resetAllocation from "../../../api/sustainability/allocation/roadUse/stepData";
import parseAndFormat from "../../../components/texts/parseAndFormat";

interface GasolineVol {
    bio_gasoline: any;
    bio_gasoline_advanced: any;
    biocomponent_in_gasoline: any;
    etanol: any;
    etanol_advanced: any;
    gasoline: any;
    nafta: any;
    nafta_advanced: any;
}

interface GasolineBioAboveMandate {
    etanol: any;
    etanol_advanced: any;
    nafta: any;
    nafta_advanced: any;
    bio_gasoline: any;
    bio_gasoline_advanced: any;
}

interface DieselVol {
    diesel: any;
    biocomponent_in_diesel: any;
    fame: any;
    fame_advanced: any;
    hvo_hro: any;
    hvo_hro_advanced: any;
}

interface DieselBioAboveMandate {
    fame: any;
    fame_advanced: any;
    hvo_hro: any;
    hvo_hro_advanced: any;
}

interface Mandate {
    name: any;
    level: any;
    required_volume: any;
    relevant_volume: any;
}

interface MandateType {
    general_mandate: any;
    advanced_sub_mandate: any;
    gasoline_sub_mandate: any;
}

interface GasolineExciseDuty {
    gasoline: any;
    bio_components_in_gasoline: any;
}

interface DieselExciseDuty {
    diesel: any;
    bio_components_in_diesel: any;
}

interface ParamsType {
    id?: any;
    gasoline_vol_card: {
        sold_vol: {
            gasoline: any,
            etanol: any,
            etanol_advanced: any,
            nafta: any,
            nafta_advanced: any,
            bio_gasoline: any,
            bio_gasoline_advanced: any
        },
        bio_above_mandate: {
            etanol: any,
            etanol_advanced: any,
            nafta: any,
            nafta_advanced: any,
            bio_gasoline: any,
            bio_gasoline_advanced: any
        },
        bio_in_fulfillment: {
            biocomponent_in_gasoline: any,
            etanol: any,
            etanol_advanced: any,
            nafta: any,
            nafta_advanced: any,
            bio_gasoline: any,
            bio_gasoline_advanced: any
        }
    };
    diesel_vol_card: {
        sold_vol: {
            diesel: any,
            fame: any,
            fame_advanced: any,
            hvo_hro: any,
            hvo_hro_advanced: any
        },
        bio_above_mandate: {
            fame: any,
            fame_advanced: any,
            hvo_hro: any,
            hvo_hro_advanced: any
        },
        bio_in_fulfillment: {
            "biocomponent_in_diesel": any,
            "fame": any,
            "fame_advanced": any,
            "hvo_hro": any,
            "hvo_hro_advanced": any
        }
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two columns with equal width
            alignItems: "center",
            gap: theme.spacing(2), // Add some gap between columns
        },
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
            padding: "10px",
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginTop: theme.spacing(2),
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
            marginLeft: "10px",
            marginRight: "10px",
            width: "250px",
            fontWeight: "bold",
        },

        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px 0",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: "2", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "12px",
                fontWeight: 700,
                backgroundColor: theme.palette.grey[300],
            },
        },
        tableContainer: {
            maxHeight: "450px",
            overflowY: "auto", // "scroll",
            "& .MuiOutlinedInput-input": {
                ...theme.typography.caption,
                fontWeight: 500,
                color: theme.palette.primary.main,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        noPadding: {
            padding: 0,
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
        bgGreen: {
            backgroundColor: "#cce6d8",
        },
        bgRed: {
            backgroundColor: "#ffdad1",
        },
        green: {
            backgroundColor: "#cce6d8",
        },
        red: {
            backgroundColor: "#ffdad1",
        },
        yellow: {
            backgroundColor: "#ffd588",
        },
    })
);

const RoadUseVolumeContainer: React.FC<Props<any>> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isConfirmVolModalOpen, setIsConfirmVolModalOpen] = useState(false);

    const handleCloseConfirmModal = () => {
        setIsConfirmVolModalOpen(false);
    };

    const handleConfirmAndSave = async () => {
        handleCloseConfirmModal();
        await handleSave();
    };
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [mandate, setMandate] = useState<MandateType>({
        general_mandate: {},
        advanced_sub_mandate: {},
        gasoline_sub_mandate: {}
    });
    const [generalMandate, setGeneralMandate] = useState<Mandate>({
        name: "General Mandate",
        level: 0,
        required_volume: 0,
        relevant_volume: 0
    });
    const [gasolineExciseDuty, setGasolineExciseDuty] = useState<GasolineExciseDuty>({
        gasoline: 0,
        bio_components_in_gasoline: 0
    });
    const [dieselExciseDuty, setDieselExciseDuty] = useState<DieselExciseDuty>({
        diesel: 0,
        bio_components_in_diesel: 0
    });

    const [advanceMandate, setAdvanceMandate] = useState<Mandate>({
        name: "Advanced Sub mandate",
        level: 0,
        required_volume: 0,
        relevant_volume: 0
    });
    const [gasolineMandate, setGasolineMandate] = useState<Mandate>({
        name: "Gasolline Sub mandate",
        level: 0,
        required_volume: 0,
        relevant_volume: 0
    });
    const [preBioGasoline, setPreBioGasoline] = useState<number>(0);
    const [preBioGasolineAdditional, setPreBioGasolineAdditional] = useState<number>(0);
    const [preBioDiesel, setPreBioDiesel] = useState<number>(0);
    const [preBioDieselAdditional, setPreBioDieselAdditional] = useState<number>(0);
    const [numericValue, setNumericValue] = useState<number>(0);
    const [rawInputs, setRawInputs] = useState<{ [key: string]: string }>({});
    const [rawBioAboveInputs, setRawBioAboveInputs] = useState<{ [key: string]: string }>({});

    const [gasolineVol, setGasolineVol] = useState<GasolineVol>({
        bio_gasoline: 0,
        bio_gasoline_advanced: 0,
        biocomponent_in_gasoline: 0,
        etanol: 0,
        etanol_advanced: 0,
        gasoline: 0,
        nafta: 0,
        nafta_advanced: 0,
    });
    const [gasolineBioAboveMandate, setGasolineBioAboveMandate] = useState<GasolineBioAboveMandate>({
        etanol: 0,
        etanol_advanced: 0,
        nafta: 0,
        nafta_advanced: 0,
        bio_gasoline: 0,
        bio_gasoline_advanced: 0
    });

    const [dieselVol, setDieselVol] = useState<DieselVol>({
        diesel: 0,
        biocomponent_in_diesel: 0,
        fame: 0,
        fame_advanced: 0,
        hvo_hro: 0,
        hvo_hro_advanced: 0
    });

    const [dieselBioAboveMandate, setDieselBioAboveMandate] = useState<DieselBioAboveMandate>({
        fame: 0,
        fame_advanced: 0,
        hvo_hro: 0,
        hvo_hro_advanced: 0
    });

    const handleGasolineVolChange = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawInputs(prevRawInputs => ({
                ...prevRawInputs,
                [fieldName]: formattedValue,
            }));

            setGasolineVol(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }

        const biocomponentGasolineVol = parseFloat(gasolineVol.etanol)
            + parseFloat(gasolineVol.etanol_advanced)
            + parseFloat(gasolineVol.nafta)
            + parseFloat(gasolineVol.nafta_advanced)
            + parseFloat(gasolineVol.bio_gasoline)
            + parseFloat(gasolineVol.bio_gasoline_advanced);

        setGasolineVol(prevValues => ({
            ...prevValues,
            ["biocomponent_in_gasoline"]: biocomponentGasolineVol
        }));
    };

    const handleGasolineBioAboveMandate = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawBioAboveInputs(prevRawBioAboveInputs => ({
                ...prevRawBioAboveInputs,
                [fieldName]: formattedValue,
            }));

            setGasolineBioAboveMandate(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }
    };

    const handleDieselVol = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawInputs(prevRawInputs => ({
                ...prevRawInputs,
                [fieldName]: formattedValue,
            }));

            setDieselVol(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }

        const biocomponentDieselVol = parseFloat(dieselVol.fame)
            + parseFloat(dieselVol.fame_advanced)
            + parseFloat(dieselVol.hvo_hro)
            + parseFloat(dieselVol.hvo_hro_advanced);

        setDieselVol(prevValues => ({
            ...prevValues,
            ["biocomponent_in_diesel"]: biocomponentDieselVol
        }));
    };

    const handleDieselBioAboveMandate = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawBioAboveInputs(prevRawBioAboveInputs => ({
                ...prevRawBioAboveInputs,
                [fieldName]: formattedValue,
            }));

            setDieselBioAboveMandate(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }
    };

    const fetchData = async () => {
        const response = await getRoadUseData();
        if (response && !response.error) {
            if (response.data.id) {
                setRoadUseId(response.data.id);
            }
            if (response.data.preli_mandate_fulfill) {
                setMandate(response.data.preli_mandate_fulfill.mandate);
            }
            if (response.data.gasoline_sold_vol) {
                setGasolineVol(response.data.gasoline_sold_vol);
            }
            if (response.data.gasoline_bio_above_mandate) {
                setGasolineBioAboveMandate(response.data.gasoline_bio_above_mandate);
            }
            if (response.data.diesel_sold_vol) {
                setDieselVol(response.data.diesel_sold_vol);
            }
            if (response.data.diesel_bio_above_mandate) {
                setDieselBioAboveMandate(response.data.diesel_bio_above_mandate);
            }
            if (response.data.gasoline_excise_duty) {
                setGasolineExciseDuty(response.data.gasoline_excise_duty);
            }

            if (response.data.diesel_excise_duty) {
                setDieselExciseDuty(response.data.diesel_excise_duty);
            }
        } else {
            alert("Some error occured");
        }
    };

    const handleSave = async () => {
        const params: ParamsType = {
            gasoline_vol_card: {
                sold_vol: {
                    gasoline: gasolineVol.gasoline,
                    etanol: gasolineVol.etanol,
                    etanol_advanced: gasolineVol.etanol_advanced,
                    nafta: gasolineVol.nafta,
                    nafta_advanced: gasolineVol.nafta_advanced,
                    bio_gasoline: gasolineVol.bio_gasoline,
                    bio_gasoline_advanced: gasolineVol.bio_gasoline_advanced
                },
                bio_above_mandate: {
                    etanol: gasolineBioAboveMandate.etanol,
                    etanol_advanced: gasolineBioAboveMandate.etanol_advanced,
                    nafta: gasolineBioAboveMandate.nafta,
                    nafta_advanced: gasolineBioAboveMandate.nafta_advanced,
                    bio_gasoline: gasolineBioAboveMandate.bio_gasoline,
                    bio_gasoline_advanced: gasolineBioAboveMandate.bio_gasoline_advanced
                },
                bio_in_fulfillment: {
                    biocomponent_in_gasoline:
                        (
                            parseFloat(gasolineVol.etanol)
                            + parseFloat(gasolineVol.etanol_advanced)
                            + parseFloat(gasolineVol.nafta)
                            + parseFloat(gasolineVol.nafta_advanced)
                            + parseFloat(gasolineVol.bio_gasoline)
                            + parseFloat(gasolineVol.bio_gasoline_advanced)
                        ) -
                        (
                            parseFloat(gasolineBioAboveMandate.etanol)
                            + parseFloat(gasolineBioAboveMandate.etanol_advanced)
                            + parseFloat(gasolineBioAboveMandate.nafta)
                            + parseFloat(gasolineBioAboveMandate.nafta_advanced)
                            + parseFloat(gasolineBioAboveMandate.bio_gasoline)
                            + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
                        ),
                    etanol: parseFloat(gasolineVol.etanol) - parseFloat(gasolineBioAboveMandate.etanol),
                    etanol_advanced: parseFloat(gasolineVol.etanol_advanced) - parseFloat(gasolineBioAboveMandate.etanol_advanced),
                    nafta: parseFloat(gasolineVol.nafta) - parseFloat(gasolineBioAboveMandate.nafta),
                    nafta_advanced: parseFloat(gasolineVol.nafta_advanced) - parseFloat(gasolineBioAboveMandate.nafta_advanced),
                    bio_gasoline: parseFloat(gasolineVol.bio_gasoline) - parseFloat(gasolineBioAboveMandate.bio_gasoline),
                    bio_gasoline_advanced: parseFloat(gasolineVol.bio_gasoline_advanced) - parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
                }
            },
            diesel_vol_card: {
                sold_vol: {
                    diesel: dieselVol.diesel,
                    fame: dieselVol.fame,
                    fame_advanced: dieselVol.fame_advanced,
                    hvo_hro: dieselVol.hvo_hro,
                    hvo_hro_advanced: dieselVol.hvo_hro_advanced
                },
                bio_above_mandate: {
                    fame: dieselBioAboveMandate.fame,
                    fame_advanced: dieselBioAboveMandate.fame_advanced,
                    hvo_hro: dieselBioAboveMandate.hvo_hro,
                    hvo_hro_advanced: dieselBioAboveMandate.hvo_hro_advanced
                },
                bio_in_fulfillment: {
                    biocomponent_in_diesel: (
                            parseFloat(dieselVol.fame)
                            + parseFloat(dieselVol.fame_advanced)
                            + parseFloat(dieselVol.hvo_hro)
                            + parseFloat(dieselVol.hvo_hro_advanced)
                        ) -
                        (
                            parseFloat(dieselBioAboveMandate.fame)
                            + parseFloat(dieselBioAboveMandate.fame_advanced)
                            + parseFloat(dieselBioAboveMandate.hvo_hro)
                            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                        ),
                    fame: parseFloat(dieselVol.fame) - parseFloat(dieselBioAboveMandate.fame),
                    fame_advanced: parseFloat(dieselVol.fame_advanced) - parseFloat(dieselBioAboveMandate.fame_advanced),
                    hvo_hro: parseFloat(dieselVol.hvo_hro) - parseFloat(dieselBioAboveMandate.hvo_hro),
                    hvo_hro_advanced: parseFloat(dieselVol.hvo_hro_advanced) - parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                }
            }
        };

        if (roadUseId) {
            params.id = roadUseId;
            const response = await getRoadUseEditData(params);
            if (!response || response.error) {
                alert("Some error occured");
            }
        } else {
            const response2 = await getRoadUseCreateData(params);
            if (!response2 || response2.error) {
                alert("Some error occured");
            }
        }
        await resetAllocation();
        return true;

    };

    const generalMandateReqVol = (( parseFloat(gasolineVol.etanol)
        + parseFloat(gasolineVol.etanol_advanced)
        + parseFloat(gasolineVol.nafta)
        + parseFloat(gasolineVol.nafta_advanced)
        + parseFloat(gasolineVol.bio_gasoline)
        + parseFloat(gasolineVol.bio_gasoline_advanced)
        + parseFloat(gasolineVol.gasoline)
        + parseFloat(dieselVol.fame)
        + parseFloat(dieselVol.fame_advanced)
        + parseFloat(dieselVol.hvo_hro)
        + parseFloat(dieselVol.hvo_hro_advanced)
        + parseFloat(dieselVol.diesel)
    ) * (parseFloat(mandate.general_mandate.level) / 100)).toFixed(2);

    const generalMandateRelVol = ((
            parseFloat(gasolineVol.etanol)
            + parseFloat(gasolineVol.etanol_advanced)
            + parseFloat(gasolineVol.nafta)
            + parseFloat(gasolineVol.nafta_advanced)
            + parseFloat(gasolineVol.bio_gasoline)
            + parseFloat(gasolineVol.bio_gasoline_advanced)
            + parseFloat(dieselVol.fame)
            + parseFloat(dieselVol.fame_advanced)
            + parseFloat(dieselVol.hvo_hro)
            + parseFloat(dieselVol.hvo_hro_advanced)
        ) -
        (
            parseFloat(gasolineBioAboveMandate.etanol)
            + parseFloat(gasolineBioAboveMandate.etanol_advanced)
            + parseFloat(gasolineBioAboveMandate.nafta)
            + parseFloat(gasolineBioAboveMandate.nafta_advanced)
            + parseFloat(gasolineBioAboveMandate.bio_gasoline)
            + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
            + parseFloat(dieselBioAboveMandate.fame)
            + parseFloat(dieselBioAboveMandate.fame_advanced)
            + parseFloat(dieselBioAboveMandate.hvo_hro)
            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
        )).toFixed(2);

    const advancedMandateReqVol = (( parseFloat(gasolineVol.etanol)
        + parseFloat(gasolineVol.etanol_advanced)
        + parseFloat(gasolineVol.nafta)
        + parseFloat(gasolineVol.nafta_advanced)
        + parseFloat(gasolineVol.bio_gasoline)
        + parseFloat(gasolineVol.bio_gasoline_advanced)
        + parseFloat(gasolineVol.gasoline)
        + parseFloat(dieselVol.fame)
        + parseFloat(dieselVol.fame_advanced)
        + parseFloat(dieselVol.hvo_hro)
        + parseFloat(dieselVol.hvo_hro_advanced)
        + parseFloat(dieselVol.diesel)
    ) * (parseFloat(mandate.advanced_sub_mandate.level) / 100)).toFixed(2);

    const advancedMandateRelVol = ((parseFloat(gasolineVol.etanol_advanced)
        + parseFloat(gasolineVol.nafta_advanced)
        + parseFloat(gasolineVol.bio_gasoline_advanced)
        + parseFloat(dieselVol.fame_advanced)
        + parseFloat(dieselVol.hvo_hro_advanced)
    ) - ( parseFloat(gasolineBioAboveMandate.etanol_advanced)
        + parseFloat(gasolineBioAboveMandate.nafta_advanced)
        + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
        + parseFloat(dieselBioAboveMandate.fame_advanced)
        + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
    )).toFixed(2);

    const subMandateReqVol = (( parseFloat(gasolineVol.etanol)
        + parseFloat(gasolineVol.etanol_advanced)
        + parseFloat(gasolineVol.nafta)
        + parseFloat(gasolineVol.nafta_advanced)
        + parseFloat(gasolineVol.bio_gasoline)
        + parseFloat(gasolineVol.bio_gasoline_advanced)
        + parseFloat(gasolineVol.gasoline)
    ) * (parseFloat(mandate.gasoline_sub_mandate.level) / 100)).toFixed(2);

    const subMandateRelVol = ((
            parseFloat(gasolineVol.etanol)
            + parseFloat(gasolineVol.etanol_advanced)
            + parseFloat(gasolineVol.nafta)
            + parseFloat(gasolineVol.nafta_advanced)
            + parseFloat(gasolineVol.bio_gasoline)
            + parseFloat(gasolineVol.bio_gasoline_advanced)
        ) -
        (
            parseFloat(gasolineBioAboveMandate.etanol)
            + parseFloat(gasolineBioAboveMandate.etanol_advanced)
            + parseFloat(gasolineBioAboveMandate.nafta)
            + parseFloat(gasolineBioAboveMandate.nafta_advanced)
            + parseFloat(gasolineBioAboveMandate.bio_gasoline)
            + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
        )).toFixed(2);

    const generalMandateStatus = () => {
        const I9: number = parseFloat(generalMandateReqVol);
        const K9: number = parseFloat(generalMandateRelVol);
        const I10: number = parseFloat(advancedMandateReqVol);
        const K10: number = parseFloat(advancedMandateRelVol);

        // @ts-ignore
        if (I9 === 0) {
            return "No road use general mandate to fulfill";
        } else {
            if (I9 <= K9) {
                return "OK (general mandate fulfilled)";
            } else {
                if (K10 < I10) {
                    return "NB! General mandate will not be fulfilled";
                } else {
                    if (I9 - I10 <= (K10 - I10) * 2) {
                        return "OK (general mandate most likely fulfilled)";
                    } else {
                        return "General mandate MAY not be fulfilled";
                    }
                }
            }
        }
    };

    const advancedMandateStatus = () => {
        const I10: number = parseFloat(advancedMandateReqVol);
        const K10: number = parseFloat(advancedMandateRelVol);

        // @ts-ignore
        if (I10 === 0) {
            return "No advanced sub-mandate to fulfill";
        } else if (I10 <= K10) {
            return "OK (advanced sub-mandate fulfilled)";
        } else {
            return "NB! Advanced sub-mandate will not be fulfilled";
        }
    };

    const gasolineMandateStatus = () => {
        const I11: number = parseFloat(subMandateReqVol);
        const K11: number = parseFloat(subMandateRelVol);

        // @ts-ignore
        if (I11 === 0) {
            return "No gasoline sub-mandate to fulfill";
        } else if (I11 <= K11) {
            return "OK (gasoline sub-mandate fulfilled)";
        } else {
            return "NB! Gasoline sub-mandate will not be fulfilled";
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setGeneralMandate(mandate.general_mandate);
        setAdvanceMandate(mandate.advanced_sub_mandate);
        setGasolineMandate(mandate.gasoline_sub_mandate);
    }, [mandate]);

    // @ts-ignore
    return (
        <div>
            <div className={classes.container}>
                <div>
                    <h1>Road use volumes</h1>
                </div>
                <div className={classes.buttonContainer}>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="primary"
                                   onClick={() => setIsConfirmVolModalOpen(true)} // Add your click handler
                    >
                        Save
                    </ButtonRounded>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="secondary"
                                   onClick={() => history.goBack()}// Add your click handler
                    >
                        Cancel
                    </ButtonRounded>
                    {isConfirmVolModalOpen && (
                        <ModalContainer
                            open={isConfirmVolModalOpen}
                            onClose={handleCloseConfirmModal}
                            title="Volumes saved"
                            maxWidth="xs"
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "16px",
                                }}
                            >
                                <Typography variant="body1" gutterBottom>
                                    The volumes have been saved successfully.
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    alignContent={"center"}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <Grid item>
                                        <br></br>
                                        <ButtonRounded
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleConfirmAndSave}
                                        >
                                            Continue
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>
                            </div>
                        </ModalContainer>
                    )}
                </div>
            </div>

            <div>
                <h3>Preliminary mandate fulfillment</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead className="MuiTableCell-head">
                            <TableRow>
                                <TableCell>Mandate Description</TableCell>
                                <TableCell>Mandate Level</TableCell>
                                <TableCell>Required Volume</TableCell>
                                <TableCell>Relevant Volume</TableCell>
                                <TableCell>Estimated Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="MuiTableBody-root">
                                <TableRow>
                                    <TableCell style={{width: "20%"}}>{ mandate.general_mandate.name }</TableCell>
                                    <TableCell style={{width: "20%"}}>{ mandate.general_mandate.level }%</TableCell>
                                    <TableCell style={{width: "20%"}}>
                                        {parseAndFormat(generalMandateReqVol, 2)}
                                    </TableCell>
                                    <TableCell style={{width: "20%"}}>
                                        {parseAndFormat(generalMandateRelVol, 2)}
                                    </TableCell>
                                    <TableCell style={{width: "20%"}} className={clsx({
                                        [classes.green]: generalMandateStatus() === "OK (general mandate fulfilled)",
                                        [classes.red]: generalMandateStatus() === "General mandate MAY not be fulfilled" || generalMandateStatus() === "No road use general mandate to fulfill" || generalMandateStatus() === "NB! General mandate will not be fulfilled",
                                        [classes.yellow]: generalMandateStatus() === "OK (general mandate most likely fulfilled)"
                                    })}>
                                        {generalMandateStatus()}
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>{ mandate.advanced_sub_mandate.name }</TableCell>
                                <TableCell style={{width: "20%"}}>{ mandate.advanced_sub_mandate.level }%</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(advancedMandateReqVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(advancedMandateRelVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}} className={clsx({
                                    [classes.green]: advancedMandateStatus() === "OK (advanced sub-mandate fulfilled)",
                                    [classes.red]: advancedMandateStatus() === "No advanced sub-mandate to fulfill" || advancedMandateStatus() === "NB! Advanced sub-mandate will not be fulfilled"
                                })}>
                                    {advancedMandateStatus()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>{ mandate.gasoline_sub_mandate.name }</TableCell>
                                <TableCell style={{width: "20%"}}>{ mandate.gasoline_sub_mandate.level }%</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(subMandateReqVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(subMandateRelVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}} className={clsx({
                                    [classes.green]: gasolineMandateStatus() === "OK (gasoline sub-mandate fulfilled)",
                                    [classes.red]: gasolineMandateStatus() === "No gasoline sub-mandate to fulfill" || gasolineMandateStatus() === "NB! Gasoline sub-mandate will not be fulfilled",
                                })}>
                                    {gasolineMandateStatus()}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Preliminary calculations of surplus volume</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Surplus Description</TableCell>
                                <TableCell>Required by customer</TableCell>
                                <TableCell>Est. Additional surplus</TableCell>
                                <TableCell>Est. Surplus to off-road</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>Bio component in gasoline</TableCell>
                                <TableCell style={{width: "25%"}}>
                                    {
                                        parseAndFormat(parseFloat(gasolineBioAboveMandate.etanol)
                                        + parseFloat(gasolineBioAboveMandate.etanol_advanced)
                                        + parseFloat(gasolineBioAboveMandate.nafta)
                                        + parseFloat(gasolineBioAboveMandate.nafta_advanced)
                                        + parseFloat(gasolineBioAboveMandate.bio_gasoline)
                                        + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasolineAdditional, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasolineAdditional, 2) }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>Bio component in diesel</TableCell>
                                <TableCell style={{width: "25%"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselBioAboveMandate.fame)
                                        + parseFloat(dieselBioAboveMandate.fame_advanced)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioDieselAdditional, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioDieselAdditional, 2) }</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>Total surplus</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasoline + preBioDiesel, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasolineAdditional + preBioDieselAdditional, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Gasoline Volumes</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Component</TableCell>
                                <TableCell>Excise duty volume</TableCell>
                                <TableCell>Sold Volume</TableCell>
                                <TableCell>Bio above mandate</TableCell>
                                <TableCell>Bio to be used in fulf.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Gasoline</TableCell>
                                <TableCell style={{width: "20%"}}>{gasolineExciseDuty.gasoline}</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="gasoline"
                                        variant="outlined"
                                        value={rawInputs["gasoline"] || parseAndFormat(gasolineVol.gasoline, 2)}
                                        onChange={handleGasolineVolChange("gasoline")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>Bio component in gasoline</TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {parseAndFormat(gasolineExciseDuty.bio_components_in_gasoline, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(gasolineVol.etanol)
                                        + parseFloat(gasolineVol.etanol_advanced)
                                        + parseFloat(gasolineVol.nafta)
                                        + parseFloat(gasolineVol.nafta_advanced)
                                        + parseFloat(gasolineVol.bio_gasoline)
                                        + parseFloat(gasolineVol.bio_gasoline_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(gasolineBioAboveMandate.etanol)
                                        + parseFloat(gasolineBioAboveMandate.etanol_advanced)
                                        + parseFloat(gasolineBioAboveMandate.nafta)
                                        + parseFloat(gasolineBioAboveMandate.nafta_advanced)
                                        + parseFloat(gasolineBioAboveMandate.bio_gasoline)
                                        + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat((
                                            parseFloat(gasolineVol.etanol)
                                            + parseFloat(gasolineVol.etanol_advanced)
                                            + parseFloat(gasolineVol.nafta)
                                            + parseFloat(gasolineVol.nafta_advanced)
                                            + parseFloat(gasolineVol.bio_gasoline)
                                            + parseFloat(gasolineVol.bio_gasoline_advanced)
                                        ) -
                                        (
                                            parseFloat(gasolineBioAboveMandate.etanol)
                                            + parseFloat(gasolineBioAboveMandate.etanol_advanced)
                                            + parseFloat(gasolineBioAboveMandate.nafta)
                                            + parseFloat(gasolineBioAboveMandate.nafta_advanced)
                                            + parseFloat(gasolineBioAboveMandate.bio_gasoline)
                                            + parseFloat(gasolineBioAboveMandate.bio_gasoline_advanced)
                                        ), 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Etanol</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="etanol"
                                        value={rawInputs["etanol"] || parseAndFormat(gasolineVol.etanol, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("etanol")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="aboveEtanol"
                                        value={rawBioAboveInputs["etanol"] || parseAndFormat(gasolineBioAboveMandate.etanol, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("etanol")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.etanol - gasolineBioAboveMandate.etanol, 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Etanol(advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="etanol_advanced"
                                        value={rawInputs["etanol_advanced"] || parseAndFormat(gasolineVol.etanol_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("etanol_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_etanol_advanced"
                                        value={rawBioAboveInputs["etanol_advanced"] || parseAndFormat(gasolineBioAboveMandate.etanol_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("etanol_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.etanol_advanced - gasolineBioAboveMandate.etanol_advanced, 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Nafta</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="nafta"
                                        value={rawInputs["nafta"] || parseAndFormat(gasolineVol.nafta, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("nafta")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_nafta"
                                        value={rawBioAboveInputs["nafta"] || parseAndFormat(gasolineBioAboveMandate.nafta, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("nafta")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.nafta - gasolineBioAboveMandate.nafta, 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Nafta(advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="nafta_advanced"
                                        value={rawInputs["nafta_advanced"] || parseAndFormat(gasolineVol.nafta_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("nafta_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_nafta_advanced"
                                        value={rawBioAboveInputs["nafta_advanced"] || parseAndFormat(gasolineBioAboveMandate.nafta_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("nafta_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.nafta_advanced - gasolineBioAboveMandate.nafta_advanced, 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Bio gasoline</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="bio_gasoline"
                                        value={rawInputs["bio_gasoline"] || parseAndFormat(gasolineVol.bio_gasoline, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("bio_gasoline")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_bio_gasoline"
                                        value={rawBioAboveInputs["bio_gasoline"] || parseAndFormat(gasolineBioAboveMandate.bio_gasoline, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("bio_gasoline")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.bio_gasoline - gasolineBioAboveMandate.bio_gasoline, 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Bio gasoline(advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="bio_gasoline_advanced"
                                        value={rawInputs["bio_gasoline_advanced"] || parseAndFormat(gasolineVol.bio_gasoline_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineVolChange("bio_gasoline_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_bio_gasoline_advanced"
                                        value={rawBioAboveInputs["bio_gasoline_advanced"] || parseAndFormat(gasolineBioAboveMandate.bio_gasoline_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleGasolineBioAboveMandate("bio_gasoline_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(gasolineVol.bio_gasoline_advanced - gasolineBioAboveMandate.bio_gasoline_advanced, 2)
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total Sold Gasoline Volumes</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    {
                                        parseAndFormat(parseFloat(gasolineVol.etanol)
                                        + parseFloat(gasolineVol.etanol_advanced)
                                        + parseFloat(gasolineVol.nafta)
                                        + parseFloat(gasolineVol.nafta_advanced)
                                        + parseFloat(gasolineVol.bio_gasoline)
                                        + parseFloat(gasolineVol.bio_gasoline_advanced)
                                        + parseFloat(gasolineVol.gasoline), 2)
                                    }
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Diesel Volumes</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Component</TableCell>
                                <TableCell>Excise duty volume</TableCell>
                                <TableCell>Sold Volume</TableCell>
                                <TableCell>Bio above mandate</TableCell>
                                <TableCell>Bio to be used in fulf.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Diesel</TableCell>
                                <TableCell style={{width: "20%"}}>{parseAndFormat(dieselExciseDuty.diesel, 2)}</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="diesel"
                                        value={rawInputs["diesel"] || parseAndFormat(dieselVol.diesel, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("diesel")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>Bio component in Diesel</TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {parseAndFormat(dieselExciseDuty.bio_components_in_diesel, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame)
                                        + parseFloat(dieselVol.fame_advanced)
                                        + parseFloat(dieselVol.hvo_hro)
                                        + parseFloat(dieselVol.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselBioAboveMandate.fame)
                                        + parseFloat(dieselBioAboveMandate.fame_advanced)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat((
                                            parseFloat(dieselVol.fame)
                                            + parseFloat(dieselVol.fame_advanced)
                                            + parseFloat(dieselVol.hvo_hro)
                                            + parseFloat(dieselVol.hvo_hro_advanced)
                                        ) -
                                        (
                                            parseFloat(dieselBioAboveMandate.fame)
                                            + parseFloat(dieselBioAboveMandate.fame_advanced)
                                            + parseFloat(dieselBioAboveMandate.hvo_hro)
                                            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                                        ), 2)

                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Fame</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="fame"
                                        value={rawInputs["fame"] || parseAndFormat(dieselVol.fame, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("fame")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_fame"
                                        value={rawBioAboveInputs["fame"] || parseAndFormat(dieselBioAboveMandate.fame, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("fame")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame) - parseFloat(dieselBioAboveMandate.fame), 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Fame (advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="fame_advanced"
                                        value={rawInputs["fame_advanced"] || parseAndFormat(dieselVol.fame_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("fame_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_fame_advanced"
                                        value={rawBioAboveInputs["fame_advanced"] || parseAndFormat(dieselBioAboveMandate.fame_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("fame_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame_advanced) - parseFloat(dieselBioAboveMandate.fame_advanced), 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>HVO/HRO</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawInputs["hvo_hro"] || parseAndFormat(dieselVol.hvo_hro, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("hvo_hro")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawBioAboveInputs["hvo_hro"] || parseAndFormat(dieselBioAboveMandate.hvo_hro, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("hvo_hro")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.hvo_hro) - parseFloat(dieselBioAboveMandate.hvo_hro), 2)
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>HVO/HRO(advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawInputs["hvo_hro_advanced"] || parseAndFormat(dieselVol.hvo_hro_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("hvo_hro_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawBioAboveInputs["hvo_hro_advanced"] || parseAndFormat(dieselBioAboveMandate.hvo_hro_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("hvo_hro_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(
                                            parseFloat(dieselVol.hvo_hro_advanced)
                                            - parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                                            , 2
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total Sold Diesel Volumes</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame)
                                        + parseFloat(dieselVol.fame_advanced)
                                        + parseFloat(dieselVol.hvo_hro)
                                        + parseFloat(dieselVol.hvo_hro_advanced)
                                        + parseFloat(dieselVol.diesel), 2)
                                    }
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>
        </div>
);
};

export default RoadUseVolumeContainer;
